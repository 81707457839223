/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    hr: "hr",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A \"session\" in Qiskit refers to a Qiskit Runtime feature that streamlines the process of calling a real quantum computer within iterative workflows."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Workflows that involve iterative communication between quantum and classical components are common (e.g., quantum machine learning and quantum chemistry problems). To negate long wait times from having to submit a new individual job to a quantum computer after each iteration, Qiskit Runtime offers a feature called \"sessions\", which lets you submit iterative hybrid workflows to available hardware or simulators once."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "On top of reducing long queuing times, sessions have an interactive timeout value (ITTL) that provides the flexibility of being able to check your results and submit new jobs to an active session within the ITTL window without having to create a new session altogether."), "\n", React.createElement(_components.p, null, "When you submit a job within a session to hardware or simulators available via Qiskit Runtime, the session begins as soon as the first job within it begins. Any subsequent calls to the requested compute resource are prioritized by the queuing system to avoid long wait times."), "\n", React.createElement(_components.p, null, "To use a session in Qiskit, place your ", React.createElement(_components.a, {
    href: "https://pennylane.ai/qml/glossary/what-are-qiskit-primitives/"
  }, "Qiskit primitives"), " within the ", React.createElement(_components.code, null, "qiskit_ibm_runtime.Session"), " context manager after creating a ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/converters#quantumcircuit---circuit-components"
  }, "Qiskit quantum circuit"), " and ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/providers"
  }, "requesting a backend"), ". More details and examples can be found in the ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit-ibm-runtime/runtime_service#primitives-and-sessions"
  }, "Qiskit sessions documentation"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
